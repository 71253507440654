/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, Header, Footer, Whatsapp } from './styles';
import Vehicle from '../../assets/VehicleMap.png';
import Products from '../../assets/products.png';
import ButtonQuotation from '../../assets/buttonQuotation.png';
import Objetive from '../../assets/objetive.png';
import Motorcycle from '../../assets/motorcycle.png';
import logoSeven from '../../assets/logo-seven-full.webp';
import appCar from '../../assets/appCar.png';
import appStore from '../../assets/appStore.png';
import buttonApp from '../../assets/buttonApp.png';
import playStore from '../../assets/playStore.png';
import linkedin from '../../assets/linkedin.png';
import facebook from '../../assets/facebook.png';
import instagram from '../../assets/instagram.png';
import whatsappIcon from '../../assets/whatsappIcon.svg';
import Fade from 'react-reveal/Fade';

function Quotation() {
  const scrollTo = (section) => {
    const element = document.getElementById(section);
    element.scrollIntoView({
      block: 'start',
      scrollPadding: '50px',
      behavior: 'smooth',
    });
  };

  const navTo = (url) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <Header>
        <Fade top>
          <div
            style={{
              display: 'flex',
              width: '85%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div className="logo">
              <img src={logoSeven} alt="" />
            </div>
            <div className="contact">
              <h3 style={{ marginRight: '25px' }}>
                Central de atendimento: <b>0800 942 7057</b>
              </h3>
              <h3>
                Maju: <b>(34) 99877-0077</b>
              </h3>
            </div>
          </div>
        </Fade>
      </Header>

      <Container>
        <div className="container-top" id="home">
          <Fade left>
            <div className="protect">
              <h3>
                Protegemos você <br />
                <span style={{ fontWeight: 300 }}>em todo </span>
                <span style={{ color: '#fbba00' }}>Brasil</span>
              </h3>
              <p>
                <span style={{ color: '#fbba00' }}>Assistência 24h</span> e
                Cobertura completa
              </p>
              <div className="quotation">
                <div className="quotation-title">
                  <p>
                    Preencha os campos abaixo com seus dados e vamos entrar em
                    contato
                  </p>
                </div>

                <div class="_form_25"></div>

                <form
                  method="POST"
                  action="https://gruposeven.activehosted.com/proc.php"
                  id="_form_25_"
                  class="_form _form_25 _inline-form  _dark"
                  novalidate
                  style={{ width: '90%' }}>
                  <input type="hidden" name="u" value="25" />
                  <input type="hidden" name="f" value="25" />
                  <input type="hidden" name="s" />
                  <input type="hidden" name="c" value="0" />
                  <input type="hidden" name="m" value="0" />
                  <input type="hidden" name="act" value="sub" />
                  <input type="hidden" name="v" value="2" />
                  <input
                    type="hidden"
                    name="or"
                    value="f4ee78ddb855c6d665d57cba2c76afb3"
                  />

                  <label>Seu nome</label>
                  <input
                    type="text"
                    id="fullname"
                    name="fullname"
                    placeholder="Digite seu nome"
                    required
                  />
                  <div className="row-quotation">
                    <div>
                      <label>E-mail</label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Digite seu email"
                        required
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div>
                      <label style={{ marginLeft: '3%' }}>Celular</label>
                      <input
                        mask="(99) 99999-9999"
                        className="inputCustom"
                        type="text"
                        id="field[2]"
                        name="field[2]"
                        placeholder=""
                        required
                        style={{ width: '100%', marginLeft: '10px' }}
                      />
                    </div>
                  </div>
                  <label>Forma de contato</label>
                  <select name="field[27]" id="field[27]">
                    <option selected>Selecione</option>
                    <option value="Ligação">Ligação</option>
                    <option value="Whatsapp">Whatsapp</option>
                  </select>

                  <input type="hidden" name="field[28]" value="" />
                  <input type="hidden" name="field[31]" value="" />
                  <input type="hidden" name="field[32]" value="" />
                  <input type="hidden" name="field[33]" value="" />
                  <input type="hidden" name="field[34]" value="" />
                  <input type="hidden" name="field[35]" value="" />
                  <input type="hidden" name="field[36]" value="" />
                  <input type="hidden" name="field[37]" value="" />
                  <button type="submit">Fazer cotação</button>
                </form>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className="vehicle-image">
              <img src={Vehicle} alt="" />
            </div>
          </Fade>
        </div>

        <Fade bottom>
          <div className="products">
            <h3>
              Proteção e cuidado <br />{' '}
              <span style={{ fontWeight: 500, color: '#fbba00' }}>
                sempre que precisar
              </span>
            </h3>
            <img src={Products} alt="" className="products-image" />
            <img
              src={ButtonQuotation}
              alt=""
              className="button-image"
              onClick={() => scrollTo('home')}
            />
          </div>
        </Fade>

        <div className="about">
          <Fade left>
            <div className="motorcycle-image">
              <img src={Motorcycle} alt="" className="button-image" />
            </div>
          </Fade>
          <Fade right>
            <div className="description-container">
              <div className="description-about">
                <div className="objetive-image">
                  <img src={Objetive} alt="" className="products-image" />
                </div>
                <div className="information">
                  <h3>+9 ANOS</h3>
                  <p>DE MERCADO</p>
                  <h3>+650 CIDADE</h3>
                  <p>COM CLIENTES ATIVOS</p>
                  <h3>+130 MIL</h3>
                  <p>VEÍCULOS CADASTRADOS</p>
                  <h3>+14 MIL</h3>
                  <p>ATENDIMENTO DE REBOQUE</p>
                </div>
              </div>
              <p className="description-seven">
                A Seven Seguros surgiu com o objetivo de entregar segurança,
                tranquilidade e conforto de forma acessível e descomplicada.
                Assim, oferecemos ao nosso cliente a autonomia para escolher o
                serviço mais adequado à sua necessidade, de forma digital e
                democrática.
              </p>
            </div>
          </Fade>
        </div>

        <div className="app">
          <Fade left>
            <div className="topic-app">
              <h1>Baixe o App</h1>
              <h2>Praticidade e Benefícios</h2>
              <h3>a um clique de você.</h3>
              <p>
                Por lá você acessa os nossos serviços e fica de olho nas
                vantagens do Club Seven!
              </p>
              <div className="image-app-download">
                <img
                  src={playStore}
                  alt=""
                  onClick={() =>
                    navTo(
                      'https://play.google.com/store/apps/details?id=br.com.seven.app'
                    )
                  }
                />
                <img
                  src={appStore}
                  alt=""
                  onClick={() =>
                    navTo(
                      'https://apps.apple.com/us/app/app-seven/id1550621458'
                    )
                  }
                />
              </div>
              <div className="button-quotation">
                <img src={buttonApp} alt="" onClick={() => scrollTo('home')} />
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className="image-app">
              <img src={appCar} alt="" />
            </div>
          </Fade>
        </div>

        <Footer>
          <Fade bottom>
            <div className="contentArea">
              <img src={logoSeven} alt="" />
              <div className="description">
                <h3>
                  CENTRAL DE ATENDIMENTO: <b>0800 942 7057</b>
                </h3>
                <h3>
                  MAJU: <b>(34) 99877-0077</b>
                </h3>
                <p>
                  ENDEREÇO:{' '}
                  <b>AV. JOÃO NAVES DE ÁVILA, 4152 - CARAJÁS, UBERLÂNDIA</b>
                </p>
              </div>
              <div className="midia">
                <h3>REDES SOCIAIS</h3>
                <div className="midias-contact">
                  <img
                    src={facebook}
                    alt=""
                    onClick={() =>
                      navTo('https://www.facebook.com/gruposevenapp')
                    }
                  />
                  <img
                    src={instagram}
                    alt=""
                    onClick={() =>
                      navTo('https://www.instagram.com/gruposeven.app')
                    }
                  />
                  <img
                    src={linkedin}
                    alt=""
                    onClick={() =>
                      navTo('https://www.linkedin.com/company/sevenappoficial/')
                    }
                  />
                </div>
              </div>
            </div>
          </Fade>
        </Footer>

        <Whatsapp
          onClick={() =>
            navTo('http://api.whatsapp.com/send?1=pt_BR&phone=5534998770077')
          }>
          <div className="whatsapp">
            <img src={whatsappIcon} alt="" />
            <h3>Whatsapp</h3>
          </div>
        </Whatsapp>
      </Container>
    </>
  );
}

export default Quotation;
