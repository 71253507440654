/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { Container, Content } from './styles';

import bancahLogo from '../../assets/bancah-logo.png';
import claroLogo from '../../assets/claro-logo.png';
import motoristaLogo from '../../assets/motoristalogo.png';
import celularImg from '../../assets/celular.png';

function LandingPageMS() {
  return (
    <Container>
      <Content>
        <div className="left">
          <img className="logo" src={motoristaLogo} />
          <h1>
            Já está disponível a <br /> <b>6ª edição</b> da revista <br />{' '}
            digital <b>Motorista de Sorte!</b>
          </h1>
          <div className="imageMobileArea">
            <img className="imageMobile" src={celularImg} />
          </div>

          <a
            href="https://seven-files.s3.amazonaws.com/6+EDIC%CC%A7A%CC%83O-MOTORISTA+DE+SORTE.pdf"
            target="_blank"
            className="button">
            Baixe agora
          </a>
          <h3>Também disponível nas plataformas:</h3>
          <div className="imageArea">
            <img className="smallLogo" src={bancahLogo} />
            <img
              className="smallLogo"
              style={{ marginTop: -3 }}
              src={claroLogo}
            />
          </div>
        </div>
        <div className="right">
          <img className="image" src={celularImg} />
        </div>
      </Content>
    </Container>
  );
}

export default LandingPageMS;
