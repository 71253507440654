import styled from 'styled-components';

const primary = '#212a51';
const secondary = '#fbba00';
const blue = '#317abd';

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 11vh;
  position: relative;
  background-color: white;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: fixed;
  z-index: 999;

  .logo {
    display: flex;
    width: 125px;
    height: 100%;

    @media (max-width: 1000px) {
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 125px;
    }
  }

  .contact {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    width: 75%;

    h3 {
      color: ${primary};
      font-weight: 400;
      font-size: 20px;
      text-transform: uppercase;
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 11vh;
  background-color: ${primary};

  .container-top {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;

    @media (max-width: 1000px) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
    }

    .vehicle-image {
      display: flex;
      width: 60%;
      align-items: center;
      justify-content: flex-start;
      height: 100%;

      @media (max-width: 1000px) {
        display: none;
      }

      img {
        width: 80%;
      }
    }

    .protect {
      display: flex;
      width: 40%;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding-left: 8%;

      @media (max-width: 1000px) {
        width: 100%;
        padding: 0;
        align-items: center;
        text-align: center;
      }

      h3 {
        font-size: 38px;
        font-weight: bold;
        color: white;
        margin: 0;
      }
      p {
        margin: 10px 0 40px 0;
        font-size: 18px;
        color: white;
      }
    }

    .quotation {
      display: flex;
      width: 350px;
      height: 310px;
      background-color: ${blue};
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      border-radius: 25px;

      @media (max-width: 450px) {
        width: 85%;
      }

      .row-quotation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        width: 97%;
      }

      label {
        display: flex;
        width: 80%;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 12px;
        color: white;
        margin: 10px 0 5px 0;
      }

      input {
        display: flex;
        width: 100%;
        height: 30px;
        border: none;
        border-radius: 5px;
        outline: none;
        box-sizing: border-box;
        padding: 0px 5px;
      }

      select {
        display: flex;
        width: 100%;
        height: 30px;
        border: none;
        border-radius: 5px;
        outline: none;
        box-sizing: border-box;
        padding: 0px 5px;
      }

      button {
        margin-top: 15px;
        width: 100%;
        height: 30px;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        transition: all ease-in-out 0.3s;
      }

      .quotation-title {
        display: flex;
        width: 346px;
        background-color: ${primary};
        margin-top: 2px;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;

        @media (max-width: 450px) {
          width: 99%;
        }

        p {
          width: 80%;
          font-size: 14px;
          margin: 15px 0;
          color: white;
        }
      }
    }
  }

  .products {
    display: flex;
    width: 84%;
    height: 100%;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    margin-top: 25px;
    border: 2px solid ${secondary};
    border-radius: 15px;

    @media (max-width: 1000px) {
      width: 85%;
      margin-top: 35px;
    }

    h3 {
      font-size: 38px;
      font-weight: bold;
      color: white;
      margin: 25px 0;

      @media (max-width: 1000px) {
        font-size: 28px;
        padding: 0 10px;
      }
    }
    .products-image {
      width: 75%;
      margin-bottom: 30px;
    }
    .button-image {
      width: 40%;
      cursor: pointer;
      transition: all ease-in-out 0.2s;

      :hover {
        opacity: 0.8;
      }

      @media (max-width: 450px) {
        width: 60%;
      }
    }
  }

  .about {
    display: flex;
    width: 85%;
    margin-top: 50px;
    flex-direction: row;
    justify-content: space-between;
    color: white;

    @media (max-width: 1000px) {
      flex-direction: column;
      justify-content: center;
    }

    .motorcycle-image {
      display: flex;
      width: 45%;
      align-items: flex-start;
      justify-content: flex-start;

      @media (max-width: 1000px) {
        display: none;
      }

      img {
        width: 100%;
      }
    }
    .description-container {
      display: flex;
      width: 85%;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      @media (max-width: 1000px) {
        width: 100%;
      }

      .description-seven {
        font-size: 16px;
        text-align: justify;
        margin-top: 45px;
        padding-left: 100px;

        @media (max-width: 1000px) {
          padding-left: 0px;
        }
      }
    }

    .description-about {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @media (max-width: 1000px) {
        justify-content: center;
      }

      .objetive-image {
        display: flex;
        width: 65%;
        height: 100%;
        align-items: center;
        justify-content: flex-end;

        @media (max-width: 1000px) {
          width: 180px;
        }

        @media (max-width: 450px) {
          width: 100px;
        }
        @media (max-width: 370px) {
          width: 80px;
        }

        img {
          width: 55%;

          @media (max-width: 1000px) {
            width: 100%;
          }
        }
      }

      .information {
        width: 40%;
        display: flex;
        align-items: flex-end;
        justify-content: top;
        flex-direction: column;

        @media (max-width: 1000px) {
          width: 100%;
        }

        h3 {
          font-size: 34px;
          color: ${secondary};
          margin: 25px 0;
        }
        p {
          font-size: 14px;
          margin: -30px 0 0 0;
        }
      }
    }
  }

  .app {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    flex-direction: row;
    color: white;

    @media (max-width: 1000px) {
      flex-direction: column;
      justify-content: center;
    }

    .topic-app {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 40%;
      padding-left: 100px;

      @media (max-width: 1000px) {
        width: 85%;
        padding: 25px 0 0 0;
      }

      .button-quotation {
        margin-top: 25px;

        @media (max-width: 1000px) {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
        }

        img {
          width: 260px;
          cursor: pointer;
          transition: all ease-in-out 0.2s;

          :hover {
            opacity: 0.8;
          }

          @media (max-width: 1000px) {
            width: 180px;
          }
          @media (max-width: 500px) {
            width: 150px;
          }
        }
      }

      h1 {
        font-size: 38px;
        color: ${secondary};
        margin: 0;
      }
      h2 {
        font-size: 30px;
        color: white;
        margin: 0;
      }
      h3 {
        font-size: 26px;
        color: white;
        font-weight: 500;
        margin: 0;
      }
      p {
        font-size: 20px;
        margin: 35px 0 0 0;
        font-weight: 300;
      }
    }

    .image-app {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 60%;
      height: 100%;

      @media (max-width: 1000px) {
        width: 100%;
        align-items: center;
        margin-top: 50px;
      }

      img {
        width: 100%;
      }
    }

    .image-app-download {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 260px;
      margin-top: 50px;

      @media (max-width: 1000px) {
        width: 100%;
        justify-content: center;
        margin-top: 30px;
      }

      img {
        width: 125px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        :hover {
          opacity: 0.8;
          transform: translatey(-5px);
        }

        @media (max-width: 1000px) {
          width: 130px;
          margin-right: 20px;
        }
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  height: 20vh;
  background-color: white;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    align-items: flex-start;
    height: 50vh;
  }
  @media (max-width: 400px) {
    align-items: flex-start;
    height: 52vh;
  }

  .contentArea {
    display: flex;
    width: 85%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    @media (max-width: 1000px) {
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      margin-top: 20px;
    }

    img {
      width: 125px;

      @media (max-width: 1000px) {
        margin-bottom: 20px;
      }
    }

    .description {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      h3 {
        font-size: 20px;
        color: ${primary};
        margin: 0;
        font-weight: 400;

        @media (max-width: 1000px) {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }

      p {
        font-size: 14px;
        color: ${primary};
        margin: 5px 0 0 0;
        font-weight: 400;
      }
    }

    .midia {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      h3 {
        font-size: 16px;
        color: ${primary};
        margin: 0;

        @media (max-width: 1000px) {
          margin-top: 20px;
        }
      }

      .midias-contact {
        display: flex;
        margin: 10px 0 0 0;
        width: 150px;
        align-items: flex-start;
        justify-content: space-around;

        img {
          width: 40px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          :hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
`;

export const Whatsapp = styled.div`
  display: none;

  @media (max-width: 1000px) {
    display: flex;
    color: white;
    width: 100vw;
    height: 8vh;
    position: fixed;
    background-color: #2ab301;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    z-index: 9999;
    bottom: 0;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    :hover {
      background-color: #25d165;
    }

    .whatsapp {
      display: flex;
      width: 80%;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 10px;
        height: 5vh;
      }
    }
  }
`;
