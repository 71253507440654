import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import LandingPageMS from './pages/LandingPageMS';
import GetQuote from './pages/GetQuote';
import Thanks from './pages/Thanks';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PublicRoute exact path="/" component={Home} />
      <PublicRoute exact path="/motorista-de-sorte" component={LandingPageMS} />
      <PublicRoute exact path="/lp-cotacao" component={GetQuote} />
      <PublicRoute exact path="/lp-cotacao/obrigado" component={Thanks} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
