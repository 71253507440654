/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import Alert from "@mui/material/Alert";

import {
  BsWhatsapp,
  BsInstagram,
  BsChevronDown,
  BsChevronUp
} from "react-icons/bs";
import { IoPersonCircleOutline } from "react-icons/io5";
import { ImFacebook, ImLinkedin2, ImYoutube } from "react-icons/im";
import { BiMenu } from "react-icons/bi";
import { IconButton } from "@mui/material";

import logoImg from "../../assets/logo-seven-full.webp";
import logoWhtImg from "../../assets/logo-seven.png";
import appStoreImg from "../../assets/app-store.webp";
import googlePlayImg from "../../assets/google-play.webp";
import bulletImg from "../../assets/bullet.png";

import rouboFurtoIcon from "../../assets/icone_roubo_e_furto.png";
import colisaoIcon from "../../assets/icone_colisao.png";
import incendioIcon from "../../assets/icone_incendio.png";
import fenomenosNaturaisIcon from "../../assets/icone_fenomenos_naturais.png";
import assistenciaIcon from "../../assets/icone_assistencia24h.png";

import adviceLogin from "../../assets/advice-login-screen.png";
import advicePayment from "../../assets/advice-payment-area.png";
import adviceRecover from "../../assets/advice-password-recover.png";

import {
  MobileMenu,
  TopBar,
  Menu,
  Header,
  Content,
  Section,
  Footer,
  QuotationButton,
  InputButton,
  QuotationClicleButton,
  SocialIconButton,
  DrawerBody,
  DialogBody,
  TutorialArea
} from "./styles";

const assistItems = [
  "REBOQUE",
  "TROCA DE PNEU",
  "PANE SECA",
  "CHAVEIRO",
  "REBOQUE PARA TERCEIRO",
  "RETORNO AO DOMICÍLIO",
  "DESTOMBAMENTO",
  "HOSPEDAGEM",
  "GUARDA DO VEÍCULO",
  "DESTOMBAMENTO",
  "CARGA DE BATERIA",
  "PANE ELÉTRICA",
  "TRANSPORTE PARA RETIRADA DO VEÍCULO APÓS SUA REPARAÇÃO",
  "TRANSPORTE PARA RETIRADA DO VEÍCULO ENCONTRADO APÓS FURTO OU ROUBO"
];

function Home() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [name, setName] = useState();
  const [city, setCity] = useState();
  const [uf, setUf] = useState();
  const [phone, setPhone] = useState();
  const [plate, setPlate] = useState();
  const [email, setEmail] = useState();

  const [advice, setAdvice] = useState(false);
  const [tutorial, setTutorial] = useState(false);

  const scrollTo = section => {
    setMobileMenuOpen(false);

    const element = document.getElementById(section);
    element.scrollIntoView({
      block: "start",
      scrollPadding: "50px",
      behavior: "smooth"
    });
  };

  const navTo = url => {
    setMobileMenuOpen(false);
    window.open(url, "_blank");
  };

  const renderDrawer = () => (
    <SwipeableDrawer
      anchor="left"
      open={mobileMenuOpen}
      onClose={() => setMobileMenuOpen(false)}
    >
      <DrawerBody>
        <img src={logoWhtImg} alt="Seven Logo" />
        <a
          href="https://api.whatsapp.com/send?phone=5534998770077"
          target="_blank"
        >
          <BsWhatsapp style={{ marginRight: 5 }} /> 34 99877 0077
        </a>

        {/* <p
          onClick={() =>
            navTo("https://www.linkedin.com/company/sevenappoficial/")
          }
        >
          Trabalhe Conosco
        </p> */}

        <p
          onClick={() =>
            navTo("https://app7.sevenprotecao.com.br/loginAssociado")
          }
        >
          Área do Associado
        </p>
        <p onClick={() => navTo("https://app7.sevenprotecao.com.br/login")}>
          Área do Colaborador
        </p>
        <a
          // href="https://app7.sevenprotecao.com.br/Api/pagamentoExterno"
          onClick={() => setAdvice(true)}
          target="_blank"
        >
          Realizar Pagamentos
        </a>
        <p
          onClick={() =>
            navTo("https://app7.sevenprotecao.com.br/Api/GerarToken")
          }
        >
          Gerar Token
        </p>
      </DrawerBody>
    </SwipeableDrawer>
  );

  const renderAdvice = () => (
    <Dialog onClose={() => setAdvice(false)} open={advice} maxWidth="md">
      <DialogBody>
        <h1 className="title">Atenção, esta página mudou!</h1>

        <p>
          Agora, antes de realizar pagamentos, você precisa se autenticar no
          sistema. O processo é bem simples e preparamos um passo a passo para
          te ajudar.
        </p>

        <button
          onClick={() => setTutorial(!tutorial)}
          className="tutorial-button"
        >
          {tutorial ? (
            <BsChevronUp style={{ marginRight: 10 }} />
          ) : (
            <BsChevronDown style={{ marginRight: 10 }} />
          )}
          {tutorial ? "Ocultar passo a passo" : "Mostrar passo a passo"}
        </button>

        <TutorialArea visible={tutorial}>
          <h3 className="subtitle">1 - Realize o login</h3>
          <p>
            Clicando em 'CONTINUAR', você será redirecionado para a tela de
            login, onde precisará informar seu usuário e senha.
          </p>

          <img className="image" src={adviceLogin} />

          <h3 className="subtitle">2 - Realize o pagamento</h3>
          <p>
            Assim que a autenticação for realizada, você será redirecionado para
            a página de pagamentos. A partir daí, basta inserir os dados
            solicitados e finalizar o pagamento.
          </p>

          <img className="image" src={advicePayment} />

          <h3 className="subtitle">Esqueceu a senha?</h3>
          <p>
            Não tem problema, na página de login basta clicar em 'ESQUECEU A
            SENHA?' e informar o seu e-mail.
          </p>

          <p>
            Você receberá um e-mail com o link para criar uma nova senha, basta
            clicar em "RESET PASSWORD", inserir seu e-mail e criar a nova senha.
          </p>

          <Alert severity="warning" style={{ marginTop: 20, marginBottom: 20 }}>
            Atenção, o e-mail pode chegar na sua caixa de SPAM.
          </Alert>

          <img className="image" src={adviceRecover} />

          <h3 className="subtitle">Precisa de ajuda?</h3>
          <p>
            Se precisar de alguma ajuda adicional, você pode entrar em contato
            conosco pela nossa Central de Atendimento (0800 942 7057) ou pelo
            WhatsApp{" "}
            <a
              href="https://api.whatsapp.com/send?phone=5534998770077"
              target="_blank"
            >
              (34) 99877-0077
            </a>
            .
          </p>
        </TutorialArea>

        <a
          onClick={() => setAdvice(false)}
          className="continue-button"
          href="https://app7.sevenprotecao.com.br/Api/pagamentoExterno"
          target="_blank"
        >
          CONTINUAR
        </a>
      </DialogBody>
    </Dialog>
  );

  return (
    <div className="App">
      {renderDrawer()}
      {renderAdvice()}

      <MobileMenu>
        <IconButton
          className="menuIcon"
          onClick={() => setMobileMenuOpen(true)}
        >
          <BiMenu color="#fff" />
        </IconButton>
        <img src={logoWhtImg} alt="Logo Seven" />
      </MobileMenu>

      <TopBar id="top">
        <Content>
          <div className="topBarArea">
            <img src={logoImg} alt="Seven" />

            <div className="topBarItem">
              <p>CENTRAL DE ATENDIMENTO</p>
              <b>0800 942 7057</b>
            </div>

            <div className="topBarItem">
              <p>MAJU</p>
              <a
                id="top-whats-app"
                elementId="top-whatsapp"
                href="https://api.whatsapp.com/send?phone=5534998770077"
                target="_blank"
              >
                <BsWhatsapp style={{ marginRight: 10 }} /> (34) 99877-0077
              </a>
            </div>

            {/* <button
              id="top-cotacao"
              elementId="top-cotacao"
              onClick={() => scrollTo('cotacao')}
              className="topBarButton">
              FAÇA UMA COTAÇÃO
            </button> */}
          </div>
        </Content>
      </TopBar>

      <Menu>
        <div className="menuArea">
          <div className="column1"> </div>
          <div className="column2">
            <p
              elementId="menu-quem-somos"
              id="menu-quem-somos"
              onClick={() => scrollTo("quem-somos")}
            >
              Quem Somos
            </p>
            <p
              elementId="menu-coberturas"
              id="menu-coberturas"
              onClick={() => scrollTo("coberturas")}
            >
              Coberturas
            </p>
            <p
              elementId="menu-planos"
              id="menu-planos"
              onClick={() => scrollTo("planos")}
            >
              Planos
            </p>
            {/* <p
              elementId="menu-trabalhe-conosco"
              id="menu-trabalhe-conosco"
              onClick={() =>
                navTo("https://www.linkedin.com/company/sevenappoficial/")
              }
            >
              Trabalhe Conosco
            </p> */}
          </div>
          <div className="column3">
            <a
              elementId="menu-area-associado"
              id="menu-area-associado"
              href="https://app7.sevenprotecao.com.br/loginAssociado"
            >
              <IoPersonCircleOutline size={20} style={{ marginRight: 10 }} />{" "}
              Área do Associado
            </a>
            <a
              elementId="menu-realizar-pagamentos"
              id="menu-realizar-pagamentos"
              onClick={() => setAdvice(true)}
              target="_blank"
            >
              Realizar Pagamentos
            </a>
            <a
              elementId="menu-gerar-token"
              id="menu-gerar-token"
              href="https://app7.sevenprotecao.com.br/Api/GerarToken"
            >
              Gerar token
            </a>
            <a
              elementId="menu-boleto"
              id="menu-boleto"
              className="boleto"
              href="https://app7.sevenprotecao.com.br/loginAssociado"
            >
              Boleto
            </a>
          </div>
        </div>
      </Menu>

      <Header id="header">
        <h1 className="line1">Estar seguro é</h1>
        <h1 className="line2">ter com quem contar.</h1>
        <div className="gradient" />
      </Header>

      <Section backgroundColor="#212a52" id="quem-somos">
        <Content>
          <div>
            <h2 className="headerTitle">Quem Somos</h2>
            <p className="headerSubtitle">
              O Grupo Seven já soma mais de 8 anos no mercado, uma história
              movida pelo cuidado com os associados e clientes em todos os
              momentos, para que possam desfrutar da vida com tranquilidade.
              Somos uma das empresas do ramo que mais cresceram nos últimos
              anos, atendendo todo o país, com mais de 110 mil associados.
            </p>
          </div>
        </Content>
      </Section>

      <Section backgroundColor="#317abd" id="coberturas">
        <Content>
          <h2 className="coverageTitle">PRINCIPAIS COBERTURAS</h2>
          <p className="coverageSubtitle">
            Oferecemos mais de 14 coberturas para proteção do seu veículo e
            assistência 24h. Rápido e sem burocracia, simples assim!
          </p>
          <div className="coveragesArea">
            <div className="coveragesColumn">
              <div className="coverage">
                <div className="coverageHeader">
                  <img src={rouboFurtoIcon} alt="ROUBO E FURTO" />
                  <h3>ROUBO E FURTO</h3>
                </div>
                <div className="coverageBody">
                  <p>
                    Roubo se caracteriza quando o motorista é abordado e seu
                    veículo apropriado por um criminoso. Furto é quando o
                    motorista não está no local do crime.
                  </p>
                </div>
              </div>

              <div className="coverage">
                <div className="coverageHeader">
                  <img src={incendioIcon} alt="INCÊNDIO" />
                  <h3>INCÊNDIO</h3>
                </div>
                <div className="coverageBody">
                  <p>
                    Proteção para seu veículo contra danos parciais ou totais
                    decorrentes de fogo, causado por problemas no próprio
                    automóvel ou por agentes externos.
                  </p>
                </div>
              </div>
            </div>

            <div className="coveragesColumn">
              <div className="coverage">
                <div className="coverageHeader">
                  <img src={colisaoIcon} alt="COLISÃO" />
                  <h3>COLISÃO</h3>
                </div>

                <div className="coverageBody">
                  <p>
                    Acidente em que um veículo em movimento sofra o impacto de
                    outro veículo. Cobre perda total.
                  </p>
                </div>
              </div>

              <div className="coverage">
                <div className="coverageHeader">
                  <img src={fenomenosNaturaisIcon} alt="FENÔMENOS NATURAIS" />
                  <h3>FENÔMENOS NATURAIS</h3>
                </div>
                <div className="coverageBody">
                  <p>
                    São tempestades, inundações, chuva de granizo, raios e queda
                    de árvores.
                  </p>
                </div>
              </div>
            </div>

            <div className="coveragesColumn">
              <div className="coverageAssist">
                <div className="coverageHeader">
                  <img src={assistenciaIcon} alt="ASSISTÊNCIA 24H" />
                  <h3>ASSISTÊNCIA 24H</h3>
                </div>
                <div className="coverageBody">
                  <ul>
                    {assistItems?.map((item, index) => (
                      <li key={index}>
                        <img src={bulletImg} />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <QuotationButton
            elementId="botao-cotacao-coberturas"
            id="botao-cotacao-coberturas"
            onClick={() => scrollTo('cotacao')}>
            FAÇA SUA COTAÇÃO AGORA
          </QuotationButton> */}
        </Content>
      </Section>

      <Section backgroundColor="#212a52" id="planos">
        <Content>
          <h2 className="plansTitle">NOSSOS PLANOS</h2>
          <p className="plansSubtitle">
            Você escolhe qual plano te atende melhor e pode alterar a qualquer
            momento incluindo os adicionais.
          </p>
          <div className="plansArea">
            <div className="plan">
              <div className="planHeader">
                <h3>Básico</h3>
              </div>
              <div className="planBody">
                <p>Roubo</p>
                <p>Furto</p>
                <p>Colisão</p>
                <p>Incêndio</p>
                <p>Fenômenos Naturais</p>
                <p>Assistência 24h</p>
                <p>APP Seven</p>
              </div>
            </div>

            <div className="plan">
              <div className="planHeader">
                <h3>Essencial</h3>
              </div>
              <div className="planBody">
                <p>Roubo</p>
                <p>Furto</p>
                <p>Colisão</p>
                <p>Incêndio</p>
                <p>Fenômenos Naturais</p>
                <p>Assistência 24h</p>
                <p>APP Seven</p>
                <p>Danos materiais a terceiros</p>
              </div>
            </div>

            <div className="plan">
              <div className="planHeader planHeaderPremium">
                <h3>Premium</h3>
              </div>
              <div className="planBody">
                <p>Roubo</p>
                <p>Furto</p>
                <p>Colisão</p>
                <p>Incêndio</p>
                <p>Fenômenos Naturais</p>
                <p>Assistência 24h</p>
                <p>APP Seven</p>
                <p>Danos materiais a terceiros</p>
                <p>Proteção de vidros (farol, lanterna e retrovisor)</p>
                <p>Carro reserva (15 dias)</p>
              </div>
            </div>
          </div>
        </Content>
      </Section>
      {/* 
      <Section gradient backgroundColor="#fefefe">
        <Content> */}
      {/* <QuotationClicleButton
            elementId="botao-cotacao-planos"
            id="botao-cotacao-planos"
            onClick={() => scrollTo('cotacao')}>
            <span>FAÇA SUA COTAÇÃO</span>
            <span className="nowText">AGORA</span>
          </QuotationClicleButton> */}
      {/* 
          <h3 className="notAssociateTitle" id="cotacao">
            Ainda não é associado Seven?
          </h3>
          <h4 className="notAssociateSubtitle">
            Faça seu cadastro aqui para saber mais!
          </h4>

          <form
            className="formArea"
            method="POST"
            action={'https://gruposeven.activehosted.com/proc.php'}
            id="_form_5_"
            novalidate>
            <input type="hidden" name="u" value="5" />
            <input type="hidden" name="f" value="5" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />
            <input
              type="hidden"
              name="or"
              value="ef254d11568dfccb43d9219fdb41453d"
            />

            <div className="inputLine">
              <div className="inputGroup">
                <label>Nome:</label>
                <input
                  className="inputCustom"
                  type="text"
                  id="firstname"
                  name="firstname"
                  label="Nome*"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="inputGroupLine">
                <div className="inputGroup">
                  <label>Cidade:</label>
                  <input
                    className="inputCustom"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    width="90%"
                    label="Cidade*"
                    type="text"
                    id="field[3]"
                    name="field[3]"
                    required
                  />
                </div>
                <div className="inputGroup">
                  <label>UF:</label>
                  <input
                    className="inputCustom"
                    width="70%"
                    label="Estado (UF)*"
                    type="text"
                    id="field[4]"
                    name="field[4]"
                    value={uf}
                    onChange={(e) => setUf(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="inputLine">
              <div className="inputGroup">
                <label>Telefone:</label>
                <input
                  className="inputCustom"
                  label="Telefone*"
                  mask="(99) 99999-9999"
                  type="text"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>

              <div className="inputGroup">
                <label>Placa do seu veículo:</label>
                <input
                  className="inputCustom"
                  label="Placa do seu veículo*"
                  mask="aaa-9*99"
                  type="text"
                  id="field[6]"
                  name="field[6]"
                  value={plate}
                  onChange={(e) => setPlate(e.target.value)}
                />
              </div>
            </div>
            <div className="inputLine">
              <div className="inputGroup">
                <label>Email:</label>
                <input
                  className="inputCustom"
                  label="Email*"
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="inputGroup">
                <InputButton
                  id="_form_19_submit"
                  elementID="_form_19_submit"
                  type="submit"
                  disabled={
                    !name || !city || !uf || !phone || !plate || !email
                  }>
                  QUERO FAZER UMA COTAÇÃO
                </InputButton>
              </div>
              <div class="_clear-element"></div>
            </div>
            <div class="_form-thank-you" style={{ display: 'none' }}></div>
          </form> */}
      {/* </Content>
      </Section> */}
    </div>
  );
}

export default Home;
