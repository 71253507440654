import React from "react";
import logoSeven from "../../assets/logo-seven.png";
import { Container } from "./styles";
import {
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineLinkedin,
  AiFillYoutube,
} from "react-icons/ai";

function Thanks() {
  const navTo = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Container>
      <div className="title">
        <img src={logoSeven} alt="" />
        <h3>Muito obrigado pelas informações!</h3>
        <p>
          Um de nossos consultores entrará em contato com você assim que
          possível para passar os detalhes de sua cotação!
        </p>
      </div>
      <div className="midias">
        <div className="midia-item">
          <AiOutlineFacebook
            size={25}
            color="#121212"
            onClick={() => navTo("https://www.facebook.com/gruposevenapp")}
          />
        </div>
        <div
          className="midia-item"
          onClick={() =>
            navTo("https://www.linkedin.com/company/sevenappoficial/")
          }
        >
          <AiOutlineLinkedin size={25} color="#121212" />
        </div>
        <div
          className="midia-item"
          onClick={() => navTo("https://www.instagram.com/gruposeven.app")}
        >
          <AiOutlineInstagram size={25} color="#121212" />
        </div>
        <div
          className="midia-item"
          onClick={() => navTo("https://www.youtube.com/c/GrupoSevenapp")}
        >
          <AiFillYoutube size={25} color="#121212" />
        </div>
      </div>
    </Container>
  );
}

export default Thanks;
