import styled from 'styled-components';
import bgImg from '../../assets/background.png';

export const TopBar = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
  font-size: 14px;

  .topBarArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    img {
      height: 30px;
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }

  .topBarItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      margin-right: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    a,
    b {
      font-weight: bold;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #317abd;
    }
  }

  .topBarButton {
    font-family: 'Montserrat', sans-serif;
    border: none;
    outline: none;
    background-color: #f8bf19;
    color: #212a52;
    border-radius: 25px;
    box-sizing: border-box;
    padding: 10px 15px;
    margin-right: 15px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
  }
`;

export const Menu = styled.nav`
  display: flex;
  flex-direction: row;
  height: 70px;
  align-items: center;
  justify-content: center;
  background-color: #22305a;

  .boleto {
    background-color: #fff;
    border-radius: 20px;
    color: #22305a;
    font-weight: bold;
    padding: 5px 15px;
  }

  .menuArea {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .column1 {
    width: 0%;
  }
  .column2 {
    width: 50%;
    padding-left: 30px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-right: 0px;
  }
  .column3 {
    width: 50%;
    border-left: 1px solid #fff;
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a,
  p {
    text-decoration: none;
    color: #fff;
    margin-right: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
    &:hover {
      color: #f8bf19;
    }
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const MobileMenu = styled.nav`
  display: none;

  @media (max-width: 1000px) {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 70px;
    align-items: center;
    justify-content: center;
    background-color: #22305a;
    padding: 0px 20px;
    a,
    p {
      text-decoration: none;
      color: #fff;
      margin-right: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transition: all ease-in-out 0.3s;
      cursor: pointer;
      &:hover {
        color: #f8bf19;
      }
    }

    img {
      height: 25px;
    }

    .menuIcon {
      position: absolute;
      left: 30px;
    }
  }
`;

export const Header = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 100px 0px;
  background-size: cover;
  background-image: url(${bgImg});
  background-repeat: no-repeat;
  height: 95vh;

  .line1 {
    color: #212a52;
    font-weight: bold;
    margin: 0px;
    margin-top: -350px;
    font-size: 40px;
  }
  .line2 {
    color: #fab800;
    margin: 0px;
    font-weight: 400;
    font-size: 40px;
  }
  .gradient {
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(33, 42, 82, 1) 80%
    );
    width: 100%;
    height: 100px;
    bottom: 0;
  }

  @media (max-width: 1000px) {
    margin-top: -80px;
    width: 100%;
    max-width: 100%;
    .line1 {
      max-width: 100%;
    }
    .line2 {
      max-width: 100%;
      text-align: center;
    }
  }
`;

export const Content = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  background: ${(props) =>
    props.gradient &&
    'linear-gradient(180deg, rgba(254,254,254,1) 0%, rgba(87,136,189,1) 100%)'};
  box-sizing: border-box;
  padding: 30px 0px;

  @media (max-width: 1000px) {
    margin-top: -10px;
  }

  .headerTitle {
    font-weight: bolder;
    color: #fff;
    font-size: 30px;

    @media (max-width: 1000px) {
      text-align: center;
    }
  }

  .headerSubtitle {
    color: #fff;
    font-size: 20px;
    margin: 10px;
    margin-bottom: 70px;
    text-align: justify;

    @media (max-width: 1000px) {
      width: 100%;
      text-align: center;
    }
  }

  .coveragesArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .coverageTitle {
    font-weight: bolder;
    color: #fff;
    font-size: 30px;
    margin: 10px;

    @media (max-width: 1000px) {
      text-align: center;
    }
  }

  .coverageSubtitle {
    color: #fff;
    font-size: 20px;
    margin: 10px;
    width: 50%;
    text-align: center;
    font-style: italic;
    margin-bottom: 70px;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .coveragesColumn {
    width: 33%;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .coverageHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #212a52;
    box-sizing: border-box;
    border-radius: 40px 40px 0px 0px;
    width: 100%;
    padding: 15px 20px;
    color: #fff;

    img {
      height: 50px;
    }

    @media (max-width: 1000px) {
      text-align: center;
    }
  }

  .coverageBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    padding: 0px 20px;
    font-size: 14px;
    text-align: center;
    color: #212a52;
    min-height: 150px;
    ul {
      width: 100%;
      padding: 0px;
      list-style-type: circle;
    }
    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      margin-bottom: 5px;
      font-size: 13px;
    }

    img {
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }

    @media (max-width: 1000px) {
      justify-content: center;
    }
  }

  .coverage {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-items: center;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 40px;
    margin: 10px;
    margin-right: 30px;
    max-width: 300px;
    height: 290px;

    @media (max-width: 1000px) {
      width: 100%;
      min-width: 250px;
      margin-right: 0px;
      margin-bottom: 50px;
      height: 290px;
    }
  }

  .coverageAssist {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 40px;
    margin: 10px;
    margin-right: 30px;
    max-width: 300px;

    @media (max-width: 1000px) {
      width: 100%;
      min-width: 250px;
      margin-right: 0px;
    }
  }

  .plansArea {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 150px;
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
      /* justify-content */
      width: 80%;
    }
  }

  .plansTitle {
    font-weight: bolder;
    color: #fff;
    font-size: 30px;
    margin: 10px;
  }

  .plansSubtitle {
    color: #fff;
    font-size: 20px;
    margin: 10px;
    width: 50%;
    text-align: center;
    font-style: italic;
    margin-bottom: 70px;

    @media (max-width: 1000px) {
      margin-bottom: 20px;
      width: 90%;
    }
  }

  .plan {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 40px;
    margin: 10px;
    margin-right: 30px;
    width: 300px;
    min-height: 430px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.5) 5px 5px 10px;
    transform: scale(0.8);
    opacity: 0.8;
    transition: all ease-in-out 0.3s;
    p {
      margin: 5px;
      text-align: center;
    }
    li {
      margin: 5px;
      text-align: center;
    }

    @media (max-width: 1000px) {
      margin-right: 0px;
      margin: 5px;
      transform: scale(1);
      opacity: 1;
    }

    &:hover {
      transform: scale(1);
      opacity: 1;
    }
  }

  .planHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #317abd;
    box-sizing: border-box;
    border-radius: 40px 40px 0px 0px;
    width: 100%;
    padding: 15px 20px;
    color: #fff;
    border: none;
    h3 {
      font-size: 26px;
      border-bottom: 3px solid #fff;
      padding-bottom: 5px;
    }
  }

  .planHeaderPremium {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8bf19;
    box-sizing: border-box;
    border-radius: 40px 40px 0px 0px;
    width: 100%;
    padding: 15px 20px;
    color: #fff;
    border: none;
  }

  .planBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 20px;
    border: none;
    line-height: 1;
    p {
      margin-bottom: 7px;
    }
  }

  .planNotFocus {
    transform: scale(0.8);
    opacity: 0.8;
  }

  .formArea {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: fls;
    justify-content: center;
    padding: 30px;
    background-color: #4083c2;
    border-radius: 30px;
    width: 75%;
    margin-bottom: 100px;

    @media (max-width: 1000px) {
      width: 100%;
      padding: 40px 20px;
    }
  }

  .inputLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .inputGroup {
    display: flex;
    flex-direction: column;
    width: 48%;
    label {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .inputGroupLine {
    display: flex;
    flex-direction: row;
    width: 48%;

    @media (max-width: 1000px) {
      flex-direction: column;
      width: 100%;
    }
  }

  .inputCustom {
    border: none;
    outline: none;
    background-color: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 2px 10px;
    height: 45px;
    margin-right: 15px;
    font-size: 16px;

    @media (max-width: 1000px) {
      margin-bottom: 10px;
    }
  }

  .notAssociateTitle {
    box-sizing: border-box;
    padding: 5px 30px;
    background-color: #212a52;
    border-radius: 30px;
    color: #fff;
    font-size: 30px;
    margin: 10px 0px;

    @media (max-width: 1000px) {
      text-align: center;
      font-size: 25px;
    }
  }

  .notAssociateSubtitle {
    color: #212a52;
    font-size: 24px;
    font-weight: normal;
    font-style: italic;
    margin: 10px 0px;
    margin-bottom: 60px;

    @media (max-width: 1000px) {
      text-align: center;
    }
  }
`;

export const Footer = styled.footer`
border-top: 1px solid white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #212a52;
  box-sizing: border-box;
  padding: 50px 0px;
  width: 100%;

  img {
    width: 250px;
    margin-bottom: 40px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  .line {
    display: flex;
    flex-direction: row;
    width: 100%;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 33%;
    margin: 5px;
    a {
      text-decoration: none;
      color: #fff;
      margin: 10px;
      transition: all ease-in-out 0.3s;
      cursor: pointer;
      &:hover {
        color: #f8bf19;
      }
    }

    @media (max-width: 1000px) {
      width: 100%;
      margin-bottom: 50px;
    }
  }

  .appTitle {
    font-size: 20px;
    color: #fff;
    margin: 0px;
  }

  .appImage {
    margin: 10px;
    height: 50px !important;
    width: auto !important;
  }

  .socialArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const QuotationButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f9bf17;
  color: #212a52;
  font-size: 26px;
  border: none;
  border-radius: 50px;
  box-sizing: border-box;
  padding: 15px 50px;
  margin: 70px 0px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 1000px) {
    font-size: 16px;
    margin: 20px 0px;
  }

  &&:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  }
`;

export const Input = styled.input`
  border: none;
  outline: none;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 2px 10px;
  height: 45px;
  margin-right: 15px;
  font-size: 16px;
`;

export const InputButton = styled.button`
  border: none;
  outline: none;
  background-color: #f8bf19;
  color: #212a52;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 2px 5px;
  height: 40px;
  margin-right: 15px;
  margin-top: 35px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  font-family: 'Montserrat', sans-serif;

  &&:disabled {
    opacity: 0.8;
    cursor: default;
    box-shadow: none;
  }

  &&:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  }
`;

export const QuotationClicleButton = styled.button`
  font-family: 'Montserrat', sans-serif;
  height: 230px;
  width: 230px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: #f8bf19;
  color: #212a52;
  margin-top: -150px;
  margin-bottom: 100px;
  font-size: 26px;
  font-weight: bold;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &&:hover {
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .nowText {
    padding: 5px 20px;
    margin-top: 5px;
    background-color: #212a52;
    color: #fff;
    border-radius: 30px;
  }
`;

export const SocialIconButton = styled.a`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: none;
  margin: none;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  color: #212a52 !important;
  margin: 10px;
  font-size: 26px;
  border: none;
  &&:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  @media (max-width: 1000px) {
    width: 40px;
    height: 40px;
    font-size: 25px;
  }
`;

export const DrawerBody = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #232f5b;
  width: 280px;
  height: 100%;
  justify-content: flex-start;
  padding-top: 120px;
  align-items: center;
  img {
    max-width: 150px;
    margin-bottom: 80px;
  }
  a,
  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 30px;
    transition: all ease-in-out 0.3s;
    &:hover {
      opacity: 0.6;
    }
  }
`;

export const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 60px;
  width: 100%;

  .title {
    color: #e74c3c;
  }

  .subtitle {
    margin: 0px;
    margin-top: 20px;
    color: #317abd;
    font-size: 26px;
  }

  .image {
    object-fit: contain;
    width: 450px;
    height: auto;
    margin: 20px 0px;
  }

  .tutorial-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    height: 35px;
    background: none;
    border: none;
    margin: 10px 0px;
    font-size: 15px;
    transition: all ease-in-out 0.3s;
    &:hover {
      color: #317abd;
    }
  }

  .continue-button {
    height: 40px;
    margin-top: 50px;
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #232f5b;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
  }

  p {
    color: #868686;
    margin: 10px 0px;
    text-align: justify;
  }

  a {
    text-decoration: none;
  }
`;

export const TutorialArea = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  transition: all ease-in-out 0.3s;
`;
