import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #000000;
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const Content = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #296e3b;
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(
    99deg,
    rgba(41, 110, 59, 1) 0%,
    rgba(0, 0, 0, 1) 75%,
    rgba(41, 110, 59, 1) 100%
  );
  position: absolute;
  height: 80%;
  width: 70%;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 30px 80px;
  color: #fff;

  @media (max-width: 900px) {
    width: 100%;
    max-width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 0px;
    background: linear-gradient(
      160deg,
      rgba(41, 110, 59, 1) 0%,
      rgba(0, 0, 0, 1) 76%,
      rgba(41, 110, 59, 1) 100%
    );
    overflow: hidden;
    padding: 20px 50px;
  }

  h1 {
    font-weight: 300;
    font-size: 22spx;
    width: 100%;

    @media (max-width: 900px) {
      font-size: 18px;
      text-align: center;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 400;

    @media (max-width: 900px) {
      font-size: 14px;
      text-align: center;
    }
  }

  b {
    font-weight: 600;
  }

  .left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media (max-width: 900px) {
      width: 100%;
      align-items: center;
    }
  }

  .right {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 900px) {
      width: 100%;
      align-items: center;
    }
  }

  .logo {
    height: 55px;
    width: auto !important;
    margin-left: -10px;
  }

  .smallLogo {
    height: 40px;
    width: auto;
  }

  .imageArea {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: -15px;
    @media (max-width: 900px) {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  .image {
    height: 80%;
    @media (max-width: 900px) {
      display: none;
    }
  }

  .imageMobileArea {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .imageMobile {
    display: none;
    @media (max-width: 900px) {
      width: 50%;
      display: flex;
      margin: 20px 0px;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #121212;
    font-family: 'Montserrat', sans-serif;
    width: 150px;
    height: 43px;
    background-color: #fff;
    font-size: 20px;
    font-weight: 600;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 20px 0px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 5px, rgba(0, 0, 0, 0.3) 0px 15px 12px;
    transition: all ease-in-out 0.3s;
    box-sizing: border-box;
    :hover {
      opacity: 0.8;
    }
  }
`;
