import styled from 'styled-components';
import background from '../../assets/backgroundThanks.jpg';

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;

    .midias{
        display: flex;
        width: 180px;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 50px;

        .midia-item{
            display: flex;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: white;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            :hover{
                opacity: 0.9;
                transform: translateY(-5px);
            }
        }
    }

    .title{
        display: flex;
        width: 80%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: white;
        text-align: center;

        h3{
            width: 500px;
            font-size: 45px;
            font-family: 'Montserrat';
            margin: 50px 0 20px 0;

            @media (max-width: 800px) {
                font-size: 38px;
                width: 100%;
            }
        }

        p{
            font-size: 18px;
            font-weight: 500;
            width: 80%;
            font-family: 'Montserrat';
            margin: 0;

            @media (max-width: 800px) {
                width: 100%;
            }
        }

        img{
            width: 150px;
        }
    }
`